<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Card Block</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link :to="{name: 'select_content'}"
                       class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </router-link>
          <div class="circles d-inline-block text-center">
            <span class="dotted_circle active"></span>
          </div>


          <button @click.prevent="validateAndStoreBlock()" class="btn---cta btn-blue btn-round">
                        <span>
                            <template v-if="!getBioLinkCardSectionAdd._id">
                                Save Block
                            </template>
                            <template v-else>
                                Update Block
                            </template>
                        </span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <h2>👉 Card</h2>
            <p>Add a card to your bio-link that allows you to showcase your content in different styles and animations.
            </p>
          </div>

          <!-- add links section -->
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <!-- custom meta -->
                <div
                  :class="{'input-field-error': cardValidation.url || cardValidation.urlValid || cardValidation.urlLength}"
                  class="input_icon_text d-flex align-items-center profile__box">
                  <div class="content">
                    <div class="url">
                      <div class="avatar mr-1">
                        <i class="fal fa-link"></i>
                      </div>
                      Get link's metadata
                    </div>
                    <div class="name">
                      <div class="validation_input d-flex">
                        <url
                          class="ml-2"
                          v-model="getBioLinkCardSectionAdd.url"
                          :validations="cardValidation"
                          v-bind:blur="() => getBioLinkCardSectionAdd.url = focusoutConcatenation(getBioLinkCardSectionAdd.url)"
                          placeholder="e.g https://mywebsite.com"></url>
                      </div>
                    </div>
                  </div>
                  <i @click="getCardPreview()" v-if="!metaPreviewLoader" class="far fa-icon-color fa-refresh"></i>
                  <clip-loader v-if="metaPreviewLoader" class="loader" :size="'16px'"
                               :color="'#3988fe'"></clip-loader>
                </div>
                <span class="input-error" v-if="cardValidation.url">{{ URLMessages.url }}</span>
                <span class="input-error" v-else-if="cardValidation.urlLength">{{ URLMessages.urlLength }}</span>
                <span class="input-error" v-else-if="cardValidation.urlValid">{{ URLMessages.urlValid }}</span>
                <upload-overlay-image-component :bgImage="getBioLinkCardSectionAdd.image" @deleteImage="deleteImage($event)" @uploadImage="uploadImage($event)"></upload-overlay-image-component>

                <!-- Title input -->
                <div
                  :class="{'input-field-error': customizeLinkPreviewValidations.title || customizeLinkPreviewValidations.titleLength}"
                  class="mt-3 input_icon_text d-flex align-items-center">
                  <div class="content">
                    <div class="d-flex url">
                      <div class="avatar mr-1">
                        <i class="fal fa-align-left"></i>
                      </div>
                      Title
                    </div>
                    <div class="name">
                      <div class="validation_input">
                        <input @input="checkTitleAndDescriptionValidations('title')" type="text" placeholder="Add a Title..." v-model="getBioLinkCardSectionAdd.title"
                               data-cy="link-title"/>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error"
                      v-if="customizeLinkPreviewValidations.title">{{ customizeLinkPreviewMessages.title }}</span>
                <span class="input-error"
                      v-else-if="customizeLinkPreviewValidations.titleLength">{{ customizeLinkPreviewMessages.titleLength }}</span>

                <!-- description input -->
                <div
                  :class="{'input-field-error': customizeLinkPreviewValidations.description || customizeLinkPreviewValidations.descriptionLength}"
                  class="mt-3 input_icon_text d-flex align-items-center">
                  <div class="content">
                    <div class="d-flex url">
                      <div class="avatar mr-1">
                        <i class="fal fa-align-left"></i>
                      </div>
                      Description
                    </div>
                    <div class="name">
                      <div class="validation_input">
                        <textarea @input="checkTitleAndDescriptionValidations('description')" type="text" placeholder="Add a Description..."
                                  v-model="getBioLinkCardSectionAdd.description"/>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error"
                      v-if="customizeLinkPreviewValidations.description">{{ customizeLinkPreviewMessages.description }}</span>
                <span class="input-error"
                      v-else-if="customizeLinkPreviewValidations.descriptionLength">{{ customizeLinkPreviewMessages.descriptionLength }}</span>

                <!-- Style component -->
                <style-component
                  @styleObj="styleObj($event)"
                  :styling="getBioLinkCardSectionAdd.style"
                ></style-component>

                <!-- Animation component-->
                <animation-component
                  @animationObj="animationObj($event)"
                  :animation_enabled="getBioLinkCardSectionAdd.animation_enabled"
                  :animation="getBioLinkCardSectionAdd.animation"
                ></animation-component>

                <!-- Schedule component -->
                <schedule-component
                  ref="schedule"
                  :enabled="getBioLinkCardSectionAdd.schedule_enabled"
                  :data="getBioLinkCardSectionAdd.schedule"
                ></schedule-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import BioShortenedURL from './BioShortenedURL'

import {
  cardValidation, customizeLinkPreviewMessages,
  customizeLinkPreviewValidations,
  scheduleValidations,
  URLMessages
} from '../../../../../common/attributes'

export default ({
  data () {
    return {
      cardValidation: JSON.parse(JSON.stringify(cardValidation)),
      URLMessages: URLMessages,
      titleLength: false,
      descriptionLength: false,
      scheduleValidations: JSON.parse(JSON.stringify(scheduleValidations)),
      debounce: null,
      metaPreviewLoader: false,
      customizeLinkPreviewValidations: customizeLinkPreviewValidations,
      customizeLinkPreviewMessages: customizeLinkPreviewMessages
    }
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    'url': () => import('@/ui/URL'),
    'schedule-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/ScheduleComponent'),
    'upload-overlay-image-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/UploadOverlayImageComponent.vue'),
    'style-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/StyleComponent'),
    'animation-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/AnimationComponent')
  },
  computed: {
    ...mapGetters([
      'getBioLinkCardSectionAdd',
      'getProfile'
    ])
  },
  created () {
    this.isBioLinkFirstStepVerified()
    this.getBioLinkCardSectionAdd.schedule = this.getBioLinkCardSectionAdd.schedule || {
      start_date: '',
      end_date: '',
      start_time: '00:00:00',
      end_time: '00:00:00',
      time_zone: ''
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions(['storeBioContentSection', 'fetchCustomizeLinkPreviewObj']),
    async validateAndStoreBlock () {
      if (this.getBioLinkCardSectionAdd.url && this.getBioLinkCardSectionAdd.url.length >= 1) {
        this.getBioLinkCardSectionAdd.url = this.urlConcatenation(this.getBioLinkCardSectionAdd.url)
        this.cardValidation.urlLength = !this.maxLength(this.getBioLinkCardSectionAdd.url, 500)
        this.cardValidation.urlValid = !this.isValidURL(this.getBioLinkCardSectionAdd.url)

        let urlCheck = Object.keys(this.cardValidation).every(k => this.cardValidation[k] === false)
        if (!urlCheck) {
          await this.$store.dispatch('toastNotification', {message: 'Please enter valid url.', type: 'error'})
          return
        }
      }

      if (!this.getBioLinkCardSectionAdd.url && !this.getBioLinkCardSectionAdd.image) {
        this.$store.dispatch('toastNotification', {message: 'Url or Image is required.', type: 'error'})
        return
      }

      if (!this.checkTitleAndDescriptionValidations('title')) {
        await this.$store.dispatch('toastNotification', {
          message: 'Title validation failed.',
          type: 'error'
        })
        return
      }

      if (!this.checkTitleAndDescriptionValidations('description')) {
        await this.$store.dispatch('toastNotification', {
          message: 'Description validation failed.',
          type: 'error'
        })
        return
      }

      this.descriptionLength = this.getBioLinkCardSectionAdd.description && !this.maxLength(this.getBioLinkCardSectionAdd.description, 1000)
      if (this.descriptionLength) {
        await this.$store.dispatch('toastNotification', {
          message: 'Description should not be greater than 500 characters.',
          type: 'error'
        })
        return
      }

      const scheduleComponentRef = this.$refs.schedule
      const proceed = await scheduleComponentRef.checkScheduleSubmission()
      if (!proceed) {
        return
      }

      this.getBioLinkCardSectionAdd.schedule_enabled = scheduleComponentRef.schedule_enabled
      if (scheduleComponentRef.schedule_enabled) {
        this.getBioLinkCardSectionAdd.schedule = scheduleComponentRef.schedule
      }

      let res = await this.storeBioContentSection('getBioLinkCardSectionAdd')
      if (res && res.data.status) {
        this.$router.push({name: 'select_content'})
      }

      if(res && !res.data.status) {
        this.alertMessage(res.data.message, 'error')
      }

    },
    styleObj (event) {
      this.getBioLinkCardSectionAdd.style = event.style
    },
    animationObj (event) {
      this.getBioLinkCardSectionAdd.animation_enabled = event.animation_enabled
      this.getBioLinkCardSectionAdd.animation = event.animation
    },
    async setCustomizeLinkPreview (value) {
      let linkPreviewObj = await this.fetchCustomizeLinkPreviewObj({'url': value})
      this.metaPreviewLoader = false
      this.getBioLinkCardSectionAdd.title = (linkPreviewObj.data && linkPreviewObj.data.meta) ? linkPreviewObj.data.meta.title : null
      this.getBioLinkCardSectionAdd.description = (linkPreviewObj.data && linkPreviewObj.data.meta) ? linkPreviewObj.data.meta.description : null
      if (linkPreviewObj.data && linkPreviewObj.data.facebook.image && (['http://', 'https://'].includes(linkPreviewObj.data.facebook.image))) {
        this.getBioLinkCardSectionAdd.image = linkPreviewObj.data.facebook.image
        return
      }
      this.getBioLinkCardSectionAdd.image = linkPreviewObj.data ? linkPreviewObj.data.images[0] : ''
    },
    uploadImage (event) {
      this.getBioLinkCardSectionAdd.image = event
    },
    deleteImage (event) {
      this.getBioLinkCardSectionAdd.image = ''
    },
    checkTitleAndDescriptionValidations (key) {
      this.customizeLinkPreviewMessages[key] = this.requiredCheck(this.getBioLinkCardSectionAdd[key])
      this.customizeLinkPreviewMessages[key + 'Length'] = !this.maxLength(this.getBioLinkCardSectionAdd[key], 500)
      return Object.keys(this.customizeLinkPreviewMessages).every(k => this.customizeLinkPreviewValidations[k] === false)
    },
    getCardPreview () {
      if (!this.isValidURL(this.getBioLinkCardSectionAdd.url)) return;
      if (!Object.keys(this.cardValidation).every(k => this.cardValidation[k] === false)) return
      this.metaPreviewLoader = true
      this.setCustomizeLinkPreview(this.getBioLinkCardSectionAdd.url)
    }
  }
})
</script>
